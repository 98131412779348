import React from "react";
import { storyblokEditable } from "@storyblok/react";
import type { PromotionStoryblok, VerticalSplitStoryblok } from "@/app/types/component-types-sb";
import Image from '@/app/components/globals/image';
import styles from './Promotion.module.css';
import VerticalSplit from "../vertical_split";

const Promotion = ({ blok }: { blok: PromotionStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} className="page-section">
      <div className="container">
        {
          blok.content?.map((nestedBlok: VerticalSplitStoryblok) => (
            <VerticalSplit blok={nestedBlok} key={nestedBlok._uid} />
          ))
        }
        <div className={styles.imageWrapper}>
          {blok.image ?
            <Image asset={blok.image} /> : null
          }
        </div>
        {blok.terms_and_conditions ?
          (
            <>
              <strong className={styles.termsAndConditionsTitle}>Terms & Conditions Apply</strong>
              <p className={styles.termsAndConditions}>{blok.terms_and_conditions}</p>
            </>
          ) : null
        }
      </div>
    </div>
  )
}

export default Promotion;