import { GroupResults } from '@/app/types/lambda-types'
import React, { useEffect, useState } from 'react'
import HolidayResult from '../holiday/result'
import Pagination from '../pagination'
import GroupItem from './GroupItem'
import styles from './DynamicGroup.module.css'

type Props = {
  group: GroupResults
}

const DynamicGroup = ({ group }: Props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    setPages(group.pages);
    setPage(1);
  }, [group.pages]);

  return (
    <div className={styles.container}>
      <Pagination page={page} setPage={setPage} pages={pages} loading={false} />
      <div className={styles.holidays}>
        {group.collection && group.collection.map((holiday, index) => (
          <GroupItem holiday={holiday} key={index} />
          // <HolidayResult holiday={holiday} key={index} />
        ))}
      </div>
      <Pagination page={page} setPage={setPage} pages={pages} loading={false} />
    </div>
  )
}

export default DynamicGroup