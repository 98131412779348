import { MapboxStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from '@storyblok/react';
import React, { useState, useEffect } from 'react';
import styles from './StoryblokMapbox.module.css'
import MapBox from '../../globals/mapbox';
import PageSection from '../../globals/page_section';
import { PortGuide } from '@/app/types/lambda-types';
import { getRegionMapCoordinates } from '@/utils/queries';

const StoryBlokMapBox = ({ blok }: { blok: MapboxStoryblok }) => {

  const [data, setData] = useState<PortGuide[]>([])

  useEffect(() => {
    const fetchData = async () => {
      if(blok.use_sub_region){
        const response = await getRegionMapCoordinates(blok.sub_region_id)
        setData(response)
      }else{
        const response = await getRegionMapCoordinates(blok.region_id)
        setData(response)
      }
    }

    fetchData()
  }, [])

  return (
    <div {...storyblokEditable(blok)}>
      {data.length > 0 && (
        <PageSection>
          <h2 className='section-header'>{blok.title}</h2>
          <div className={styles.map}>
            <MapBox points={data?.map((item) => ({
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
              label: item.name,
              description: item.description,
              image_path: item.image_path
            }))} />
          </div>
        </PageSection>
      )}
    </div>
  )
}

export default StoryBlokMapBox