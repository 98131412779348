import React from 'react'
import styles from './Categories.module.css'
import { CategoriesStoryblok, CategoryStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import Container from '@/app/components/globals/container';
import PageSection from '@/app/components/globals/page_section';
import Category from './Category';


const Categories = ({ blok }: { blok: CategoriesStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h1>{blok.title}</h1>
          <div className={styles.categories}>
            {blok.knowledge_categories.map((category: CategoryStoryblok) => (
              <Category blok={category} key={category._uid} />
            ))}
          </div>
        </Container>
      </PageSection>
    </div>
  )
}

export default Categories