import { Holiday } from "@/app/types/lambda-types"
import Image from '@/app/components/globals/image';
import { formatDate } from "@/utils/configs";
import styles from './DynamicGroup.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faLocationDot, faShip } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import PriceCircle from "../price-circle";
import CustomLink from "../link";
import Included from "../../storyblok-globals/star_buy/Included";
import getIncluded from "@/utils/data-manipulation/getIncluded";

type Props = {
  holiday: Holiday
}

const GroupItem = ({ holiday }: Props) => {
  return (
    <article className={styles.wrapper}>
      <div className={styles.backgroundImage}>
        <Image asset={holiday.background_image || ''} />
      </div>
      <div className={styles.darkGradientOverlay}></div>
      <header className={styles.header}>
        <div className={styles.cruiseNationLogo}>
          <Image asset='https://a.storyblok.com/f/306333/150x150/6e56b55986/cn-icon-sqaure.svg' />
        </div>
        <div className={styles.itemHeader}>
          <h3>{holiday.name}</h3>
          <p className={styles.detail}>
            <span>
              <FontAwesomeIcon icon={faCalendarDay} />
              {formatDate(holiday.start_date).format("Do MMM YYYY")}
            </span>
            <span>
              <FontAwesomeIcon icon={faShip} />
              <span className={styles.clickableItem}>
                {holiday.ship?.name}
              </span>
            </span>
            <span className={styles.itemHeaderItem}>
              <FontAwesomeIcon icon={faLocationDot} />
              <span className={styles.clickableItem}>
                View Itinerary
              </span>
            </span>
          </p>
        </div>
      </header>

      <div className={styles.body}>
        <PriceCircle price={holiday.from_price || 'Check'} />
        <footer className={styles.footer}>
          <div className={styles.includeds}>
            <p>Included</p>
            <div className={styles.included}>
              {holiday.extras ? getIncluded(holiday.extras).map((extra, key) => (
                <Included key={key} holidayExtra={extra} mergeHotels={true} />
              )) : null}
            </div>
          </div>
          <CustomLink href={holiday.url} className={styles.viewButton} asButton>
            View Deal
          </CustomLink>
        </footer>
      </div>
    </article>
  );
}

export default GroupItem;