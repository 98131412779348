import React from "react";
import { storyblokEditable, StoryblokComponent, SbBlokData } from "@storyblok/react";
import type { VerticalSplitStoryblok } from "@/app/types/component-types-sb";
import styles from './VerticalSplit.module.css';
import Container from "../../globals/container";

const VerticalSplit = ({ blok }: { blok: VerticalSplitStoryblok }) => {
  const sameBlokBothSides = ((blok.left_column && blok.left_column.length > 0) &&
    (blok.right_column && blok.right_column.length > 0)) &&
    blok.left_column[0].component === blok.right_column[0].component;

  return (
    <div className="page-section" {...storyblokEditable(blok)}>
      <Container>
        <h2 className="section-header">{blok.header}</h2>
        <div className={`${styles.wrapper} ${sameBlokBothSides ? styles.wrapperTop : ''}`}>
          <div className={`${styles.column} ${styles.columnLeft}`}>
            {
              blok.left_column?.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} side='left' key={nestedBlok._uid} />
              ))
            }
          </div>
          <div className={`${styles.column} ${styles.columnRight}`}>
            {
              blok.right_column?.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} side='right' key={nestedBlok._uid} />
              ))
            }
          </div>
        </div>
      </Container>

      {
        blok.page_section?.map((pageSection: SbBlokData, key) => (
          <div className={styles.pageSection} key={key}>
            <StoryblokComponent blok={pageSection} key={pageSection._uid} />
          </div>
        ))
      }
    </div>
  )
}

export default VerticalSplit;