import styles from "./Header.module.css"
import Image from '../../globals/image';
import type { HeaderStoryblok } from '@/app/types/component-types-sb';
import Container from '../../globals/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from "@awesome.me/kit-d4c82d9167/icons/classic/thin";
import React from 'react'
import clsx from "clsx";
import SearchBar from "../../globals/search-bar";

type Props = {
  blok: HeaderStoryblok
}

const FullHeader = ({ blok }: Props) => {
  const scrollHeader = () => {
    window.scrollTo({
      top: window.innerHeight - 310,
      behavior: 'smooth'
    });
  }
  return (
    <div className={styles.main}>
      <div className={styles.imageWrapper}>
        {blok.background_image ?
          <Image lazyLoad={false} asset={blok.background_image} quality={70} /> : null
        }
      </div>
      <div className={styles.overlay} />
      <Container className={styles.container}>
        <SearchBar />
        <p className={styles.subtitle}>{blok.section}</p>
        <h1 className={styles.title}>{blok.title}</h1>
        {blok.stats?.map((stat, index) => (
          <div key={index} className={styles.stats}>
            {stat.stats_item?.map((stat_item, index_) => (
              <div key={index_} className={styles.statItem}>
                <div className={styles.statImage}>
                  <Image
                    lazyLoad={false}
                    size={{
                      width: 14,
                      height: 14
                    }}
                    className={styles.statIconStyle}
                    asset={stat_item.icon || ''}
                  />
                </div>
                <p className={styles.label}>{stat_item.label}</p>
              </div>
            ))}
          </div>
        ))}

        <div className={clsx(styles.headerScrollButtonHolder, {
          [styles.statsVisible]: blok.stats && blok.stats.length > 0
        })}>
          <p className={styles.headerScrollButtonTitle}>Discover</p>
          <FontAwesomeIcon
            color='white'
            className={styles.headerScrollButton}
            size='3x'
            onClick={scrollHeader}
            icon={faArrowDown} />
        </div>
      </Container>
    </div>
  )
}

export default FullHeader