import styles from './FromPrice.module.css';

type Props = {
  price: number | string;
}

const FromPrice = ({ price }: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.from}>
        From
      </span>
      <span className={styles.price}>
        {
          price ?
            `£${price}`
            : null
        }
      </span>
      <span className={styles.PP}>PP*</span>
    </div>
  )
}

export default FromPrice;