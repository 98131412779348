import { GroupStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from '@storyblok/react'
import React, { useState, useEffect } from 'react'
import Container from '../../globals/container'
import { GroupResults } from '@/app/types/lambda-types'
import { getDynamicGroups } from '@/utils/queries'
import DynamicGroup from '../../globals/dynamic_group'


const Group = ({ blok }: { blok: GroupStoryblok }) => {

  const [group, setGroup] = useState<GroupResults | undefined>();

  useEffect(() => {
    async function fetchDynamicGroups() {
      if (!blok.group_uuid) return;

      const result = await getDynamicGroups(
        blok.group_uuid,
        blok.page_size || '12',
        blok.sort_by || undefined,
        blok.sort_direction || undefined,
        true
      );
      if (result) {
        setGroup(result);
      }
    }
    fetchDynamicGroups();
  }, [])

  return (
    <div className="page-section story-blokgroups" {...storyblokEditable(blok)}>
      <Container>
        <h2 className="section-header">
          {blok.title && blok.title !== '' ? blok.title : 'Hand-Picked Holidays'}
        </h2>
        {group && (
          <DynamicGroup group={group} />
        )}
      </Container>
    </div>
  )
}

export default Group