"use client"
import { useRef, useEffect, useState } from 'react'
import NavigationParent from './Navigation'

type Props = {
  children: React.ReactNode
}

const NavigationWrapper = ({ children }: Props) => {
  const [isVisible, setIsVisible] = useState(true)
  const navigationRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        threshold: 0,
      }
    )

    if (navigationRef.current) {
      observer.observe(navigationRef.current)
    }

    return () => {
      if (navigationRef.current) {
        observer.unobserve(navigationRef.current)
      }
    }
  }, [])

  return (
    <div ref={navigationRef}>
      <NavigationParent isVisible={isVisible}>
        {children}
      </NavigationParent>
    </div>
  )
}

export default NavigationWrapper