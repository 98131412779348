"use client"
import React, { useRef, useState, useEffect, ReactElement } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './MapBox.module.css'
import {
  AlertDialog,
  AlertDialogContent,
} from '../alert-dialog/AlertDialog';
import Image from '../image';
import Button from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/regular"

type Props = {
  points: {
    lng: number;
    lat: number;
    label?: string;
    description?: string
    image_path?: string
  }[]
}


const INITIAL_ZOOM = 10.12


/**
 * A Mapbox component that displays a map with markers at the given points.
 *
 * @param {Props} props
 * @param {Array<{lng: number, lat: number, label?: string, description?: string, image_path?: string}>} props.points
 * @returns {ReactElement}
 */
const MapBox = ({ points }: Props): ReactElement => {
  const mapRef = useRef<mapboxgl.Map | null>(null)
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<mapboxgl.Popup | null>(null)

  const [center, setCenter] = useState<[number, number]>([
    points[0].lng,
    points[0].lat
  ])
  const [selectedPoint, setSelectedPoint] = useState<{ lng: number, lat: number, label?: string, description?: string, image_path?: string } | null>(null);
  const [zoom, setZoom] = useState<number>(INITIAL_ZOOM)

  useEffect(() => {
    if (!mapContainerRef.current) return;

    mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAP_BOX_ACCESS_TOKEN || ''
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      zoom: zoom,
      scrollZoom: false,
      projection: { name: 'mercator' },
      minZoom: 2,
      maxPitch: 0,
      bearing: 0
    });

    const bounds = new mapboxgl.LngLatBounds();

    if (!mapRef.current) return;

    points.forEach(point => {
      if (!mapRef.current) return;

      if (isNaN(point.lng) || isNaN(point.lat)) return

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25
      }).setHTML(`
        <div style="padding: 8px">
          <strong style="color: #452325">${point.label || ''}</strong>
        </div>
      `);

      bounds.extend([point.lng, point.lat]);
      const marker = new mapboxgl.Marker({ color: '#f4081f' })
        .setLngLat([point.lng, point.lat])
        .addTo(mapRef.current)
        .getElement()

      marker.style.cursor = 'pointer';

      marker.addEventListener('mouseenter', () => {
        if (!mapRef.current) return;
        
        // Remove existing popup if any
        if (popupRef.current) popupRef.current.remove();
        
        // Set and show new popup
        popupRef.current = popup;
        popup.addTo(mapRef.current)
          .setLngLat([point.lng, point.lat]);
      });

      // Hide popup when mouse leaves
      marker.addEventListener('mouseleave', () => {
        if (popupRef.current) {
          popupRef.current.remove();
          popupRef.current = null;
        }
      });

      marker.addEventListener('click', () => {
        setSelectedPoint(point);
        // Optionally remove popup when clicking
        if (popupRef.current) {
          popupRef.current.remove();
          popupRef.current = null;
        }
      });
    });

    if (!bounds.isEmpty()) {
      mapRef.current.fitBounds(bounds, {
        padding: 50,
        duration: 1000
      });
    }

    mapRef.current.scrollZoom.disable();
    mapRef.current.on('wheel', (e) => {
      if (e.originalEvent.ctrlKey) {
        mapRef.current?.scrollZoom.enable();
      } else {
        mapRef.current?.scrollZoom.disable();
        e.preventDefault();  
      }
    });

    mapRef.current.on('move', () => {
      if (!mapRef.current) return;

      const mapCenter = mapRef.current.getCenter()
      const mapZoom = mapRef.current.getZoom()


      setCenter([mapCenter.lng, mapCenter.lat])
      setZoom(mapZoom)
    })

    return () => {
      if (!mapRef.current) return;
      mapRef.current.remove()
    }
  }, [])

  return (
    <>
      <div id='map-container' ref={mapContainerRef} style={{
        width: '100%',
        height: '100%'
      }} />
      {selectedPoint && (
        <AlertDialog open={!!selectedPoint} onOpenChange={() => setSelectedPoint(null)}>
          <AlertDialogContent showBackdrop={false}>
            <div className={styles.popUp}>
              <div className={styles.alertDialogMain}>
                <div className={styles.highlightImage}>
                  <Image alt={selectedPoint.label} lazyLoad={false} className={styles.higlightBackgroundOverlay} asset={selectedPoint?.image_path || ''} size={{
                    width: 1280,
                    height: 720
                  }} />
                </div>
                <div className={styles.overlay} />

                <div className={styles.alertDialogInformation}>
                  <h1 className={styles.highlightDialogTitle}>{selectedPoint.label}</h1>
                  <div className={styles.alertDescription}>
                    <p>
                      {selectedPoint.description}
                    </p>
                  </div>
                  <Button variant='transparent' onClick={() => setSelectedPoint(null)} className={styles.alertDialogClose}>
                    <FontAwesomeIcon size='3x' icon={faXmark} className={styles.alertDialogCloseIcon} />
                  </Button>
                </div>
              </div>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  )
}

export default MapBox