import Button from "../button";
import styles from './Pagination.module.css';

type Props = {
  page: number;
  active: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const PaginationLink = ({ page, active = false, disabled = false, onClick }: Props) => {
  return (
    <li>
      <Button
        className={styles.button}
        onClick={() => {
          if (!disabled && !active && onClick) {
            onClick()
          }
        }}
        variant={active ? 'primary' : 'outline'}
        disabled={disabled}
      >
        {page}
      </Button>
    </li>
  )
}

export default PaginationLink;