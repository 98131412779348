import React from 'react'
import { storyblokEditable } from "@storyblok/react/rsc";
import type { HeaderStoryblok } from '@/app/types/component-types-sb';
import FullHeader from './FullHeader';
import ShortHeader from './ShortHeader';

const Header = ({ blok }: { blok: HeaderStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.style === '2' ? (
        <ShortHeader blok={blok} />
      ) : (
        <FullHeader blok={blok} />
      )
      }
    </div>
  );
}

export default Header