import React from "react";
import { storyblokEditable, StoryblokComponent, SbBlokData } from "@storyblok/react";
import type { FullWidthImageStoryblok } from "@/app/types/component-types-sb";
import Image from '@/app/components/globals/image';
import styles from './FullWidthImage.module.css';

const FullWidthImage = ({ blok }: { blok: FullWidthImageStoryblok }) => {
  return (
    <div className="page-section" {...storyblokEditable(blok)}>
      {blok.title ?
        <h2 className="section-header">{blok.title}</h2> : null
      }
      <div className={styles.imageWrapper}>
        {blok.image ?
          <Image asset={blok.image} /> : null
        }
      </div>
      {
        blok.content?.map((nestedBlok: SbBlokData) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))
      }
    </div>
  )
}

export default FullWidthImage;