import clsx from 'clsx'
import React, { HTMLAttributes, ReactElement, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

/**
 * A basic page section component that wraps children in a div with class 'page-section'
 *
 * @param {ReactNode} children - The content to be rendered inside the page section.
 * @param {string} [className] - Additional CSS class names to be applied to the page section element.
 * @param {HTMLAttributes<HTMLDivElement>} [props] - Any additional props to be applied to the page section element.
 *
 * @returns {ReactElement} - The page section element.
 */
const PageSection = ({ children, className, ...props }: Props & HTMLAttributes<HTMLDivElement>): ReactElement => {
  return (
    <div {...props} className={clsx('page-section', className)}>
      {children}
    </div>
  )
}

export default PageSection