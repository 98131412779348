import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './SearchBar.module.css';

type Port = {
  label: string;
  value: string | null;
  selected?: boolean;
};

type Destination = {
  label: string;
  value: string;
  selected?: boolean;
};

interface DestinationDropdownProps {
  destinations: Destination[];
  startingPorts: Port[];
  visitingPorts: Port[];
  selectedDestination: Destination;
  onDestinationChange: (destination: Destination) => void;
}

const DestinationDropdown: React.FC<DestinationDropdownProps> = ({
  destinations,
  startingPorts,
  visitingPorts,
  selectedDestination,
  onDestinationChange
}) => {
  return (
    <div className={styles.dropdownDestination}>
      <div className={styles.dropdownDropdownListOptions}>
        {destinations.map((dest) => (
          <button
            key={dest.value}
            className={`${styles.listOption} ${selectedDestination.value === dest.value ? styles.listOptionSelected : ''
              }`}
            onClick={() => onDestinationChange(dest)}
          >
            {dest.label}
          </button>
        ))}
      </div>
      <div className={styles.advancedSearchInputGroups}>
        <div className={styles.advancedSearchInputGroup}>
          <div className={styles.advancedSearchStartingPort}>
            <select>
              {startingPorts.map((port) => (
                <option key={port.value} value={port.value || ''}>
                  {port.label}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.advancedSearchVisitingPort}>
            <select>
              {visitingPorts.map((port) => (
                <option key={port.value} value={port.value || ''}>
                  {port.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationDropdown;