'use client';

import React, { useEffect, useRef, useState } from 'react';
import styles from './SignupModal.module.css';
import { getCookie, setCookie } from '@/utils/cookies';

const ASSET_PATH = 'https://static.traveltek.net/images/www.cruisenation.com/assets/img/signup-modal/2023/';
const TWO_MINUTES = 1000 * 60 * 2;
const TWO_SECONDS = 1000 * 2;

const templates = [
  {
    backgroundImage: ASSET_PATH + 'desktop-01-background.jpg',
    foregroundImage: ASSET_PATH + 'desktop-01-text.png',
    mobileBackgroundImage: ASSET_PATH + 'mobile-01-background.jpg',
    mobileForegroundImage: ASSET_PATH + 'mobile-01-text.png',
    modifier: '',
    buttonText: 'GET DISCOUNTS'
  },
  // ... other templates
];

const getRandomTemplate = () => templates[Math.floor(Math.random() * templates.length)];

const SignupModal = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [mounted, setMounted] = useState(false);
  const [templateData, setTemplateData] = useState(templates[0]);
  const [waitTime, setWaitTime] = useState(TWO_MINUTES);

  // Handle client-side only initialization
  useEffect(() => {
    setMounted(true);
    setTemplateData(getRandomTemplate());
    
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      setWaitTime(TWO_SECONDS);
    }
  }, []);

  useEffect(() => {
    if (!mounted) return;

    const checkModalDisplay = () => {
      const hasSeenModal = getCookie('modalSeen');
      const hasSignedUp = getCookie('signedUp');

      if (hasSignedUp) return;

      if (!hasSeenModal) {
        const timer = setTimeout(() => {
          dialogRef.current?.showModal();
        }, waitTime);
        return () => clearTimeout(timer);
      }
    };

    checkModalDisplay();
  }, [mounted, waitTime]);

  const handleClose = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setCookie('modalSeen', 'true', { expires: tomorrow });
    dialogRef.current?.close();
  };

  const handleSubmit = () => {
    const farFuture = new Date();
    farFuture.setFullYear(farFuture.getFullYear() + 10);
    setCookie('signedUp', 'true', { expires: farFuture });
  };

  if (!mounted) {
    return null;
  }

  return (
    <dialog
      ref={dialogRef}
      id='signup-modal'
      className={styles.modal}
      onClick={(e) => {
        if (e.target === dialogRef.current) handleClose();
      }}
    >
      <div className={styles.container}>
        <img
          src={templateData.backgroundImage}
          draggable="false"
          className={styles.background}
          alt=""
        />
        <img
          src={templateData.mobileBackgroundImage}
          draggable="false"
          className={styles.backgroundMobile}
          alt=""
        />
        <div className={`${styles.formContainer} ${templateData.modifier}`}>
          <img
            src={templateData.foregroundImage}
            draggable="false"
            className={styles.foreground}
            alt=""
          />
          <img
            src={templateData.mobileForegroundImage}
            draggable="false"
            className={styles.foregroundMobile}
            alt=""
          />
          <form
            action="https://cruisenation.us4.list-manage.com/subscribe/post?u=716fceb783c7335b8c574f3a7&amp;id=e14a1838e8&amp;f_id=00fbc2e1f0"
            method="post"
            onSubmit={handleSubmit}
          >
            <div className={styles.hidden}>
              <input
                type="text"
                name="b_716fceb783c7335b8c574f3a7_b6cd736d48"
                tabIndex={-1}
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              placeholder="First Name"
              name="FIRSTNAME"
              required
              autoComplete="given-name"
              title="First name"
              className={styles.input}
            />
            <input
              type="email"
              placeholder="Email Address"
              name="EMAIL"
              required
              autoComplete="email"
              title="Email Address"
              className={styles.input}
            />
            <input type="hidden" name="AFF" value="" />
            <button
              className={styles.submit}
              type="submit"
              name="Submit"
            >
              {templateData.buttonText}
            </button>
            <button
              type="button"
              className={styles.close}
              onClick={handleClose}
            >
              <i className="far fa-times" /> No thank you, I&apos;d rather pay full price.
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default SignupModal;