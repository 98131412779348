import React, { FC, HTMLAttributes, ReactElement } from 'react'
import { AssetStoryblok } from '@/app/types/component-types-sb';


type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>

type Range<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

type T = Range<1, 100>

// TODO: Make this component more flexible, maybe move the wrapper to the parent component?
type Props = {
    asset: AssetStoryblok | string
    className?: string
    crop?: boolean
    lazyLoad?: boolean
    quality?: T
    size?: {
        width: number
        height: number
    }
    alt?: string
    style?: React.CSSProperties
}


/**
 * A component to render an image with optional cropping and sizing.
 *
 * @param {Props} props
 * @prop {AssetStoryblok | string} asset - The asset to render, can be a Storyblok asset or a string URL.
 * @prop {boolean} crop - Whether to crop the image to the size provided.
 * @prop {{ width: number; height: number }} size - The size to render the image in.
 * @prop {string} className - Additional CSS class names to apply.
 * @prop {React.CSSProperties} style - Additional CSS styles to apply.
 * @prop {boolean} lazyLoad - Whether to lazy load the image.
 * @prop {number} quality - The quality parameter for the image URL. A number between 1 and 100
 * @prop {string} alt - The alt text for the image.
 * @returns {ReactElement} - The rendered image element.
 */
const Image: FC<Props & HTMLAttributes<HTMLImageElement>> = ({
    asset,
    size,
    crop,
    className,
    lazyLoad = true,
    style,
    quality,
    alt,
    ...props
}: Props): ReactElement => {
    if (!asset) return (<></>)

    const fileType = (filename: string) => {
        return filename?.split('.').pop()
    }

    /**
     * Returns a string representing the quality parameter for the image URL.
     * 
     * @returns {string} The quality parameter string in the format ':quality(value)' if quality is provided, otherwise an empty string.
     */
    const getQuality = (): string => (quality ? `:quality(${quality.toString()})` : '')

    /**
     * Generates a URL for the image asset without cropping, considering the file type and size.
     *
     * @returns {string | undefined} - The generated URL for the image asset or undefined if no asset is provided.
     *
     * If the asset is not provided, returns undefined.
     * If the size is not provided, generates a URL with default format or WebP conversion for non-SVG files.
     * If the focus is not provided, generates a URL with the specified size and WebP conversion for non-SVG files.
     */
    const generateUrlWithoutCrop = () => {
        const focusAsset = asset as AssetStoryblok;
        if (!focusAsset) return
        if (!size) return `${focusAsset.filename as string}/m/${fileType(focusAsset.filename as string) !== 'svg' ? `filters:format(webp)${getQuality()}` : ''}`

        return `${focusAsset.filename as string}/m/${size.width}x${size.height}${fileType(focusAsset.filename as string) !== 'svg' ?
            `/filters:format(webp)${getQuality()}` : ''}`
    }

    /**
     * Generates a URL for the image asset, potentially with cropping and focal point adjustments,
     * considering the file type and provided size.
     *
     * @returns {string} - The generated URL for the image asset.
     *
     * The function returns an empty string if no asset is provided.
     * If cropping is disabled, it returns a URL generated by `generateUrlWithoutCrop`.
     * If no size is provided, it generates a URL with default format or WebP conversion for non-SVG files.
     * If no focus is specified, it generates a URL with the specified size and WebP conversion for non-SVG files.
     * If a focus is specified, it calculates new focal points based on the specified size and generates a URL
     * with WebP conversion and focal point adjustments for non-SVG files.
     */
    const generateUrl = () => {
        const focusAsset = asset as AssetStoryblok;
        if (!focusAsset) return ''
        if (!crop) return generateUrlWithoutCrop()
        if (!size) return `${focusAsset.filename as string}/m/${fileType(focusAsset.filename as string) !== 'svg' ? `filters:format(webp)${getQuality()}` : ''}`
        if (!focusAsset.focus) return `${focusAsset.filename as string}/m/${size.width}x${size.height}${fileType(focusAsset.filename as string) !== 'svg' ?
            `/filters:format(webp)${getQuality()}` : ''}`

        const { focus } = focusAsset;
        const leftAndTop = focus.split(':')[0];
        const rightAndBottom = focus.split(':')[1];

        const [left, top] = leftAndTop.split('x');
        const [right, bottom] = rightAndBottom.split('x');

        const newLeft = Math.ceil(parseInt(left) - (size.width / 100));
        const newTop = Math.ceil(parseInt(top) - (size.height / 100));
        const newRight = Math.ceil(parseInt(right) + (size.width / 100));
        const newBottom = Math.ceil(parseInt(bottom) + (size.height / 100));

        const newFocus = `${newLeft}x${newTop}:${newRight}x${newBottom}`;

        return `${focusAsset.filename as string}/m/${size.width}x${size.height}${fileType(focusAsset.filename as string) !== 'svg' ?
            `/filters:format(webp):focal(${newFocus})${getQuality()}` : ''}`
    }

    // TODO: remove inline styles
    const ImageElement = (
        <img
            className={`${className ? className : ''}`}
            style={{
                ...style,
                objectFit: 'cover',
                position: 'absolute',
                height: '100%',
                width: '100%',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                color: 'transparent'
            }}
            {...props}
            loading={lazyLoad ? 'lazy' : 'eager'}
            src={typeof asset === 'string' ? asset : generateUrl()}
            alt={typeof asset === 'string' ? (alt ?? '') : (asset.alt ?? '')}
        />
    )

    return (
        <div className={`absolute w-full h-full`} style={{ top: 0, left: 0 }}>
            {ImageElement}
        </div>
    )
}

export default Image