import { WhatsIncludedItemStoryblok } from '@/app/types/component-types-sb'
import { WhatsIncludedType } from '@/app/types/lambda-types';
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from './WhatsIncluded.module.css';
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTicket } from '@awesome.me/kit-d4c82d9167/icons/classic/solid';

const WhatsIncludedItem = ({ blok }: { blok: WhatsIncludedItemStoryblok | WhatsIncludedType }) => {
  if (blok.description) {
    return (
      <div className={styles.whatsIncludedItem}>
        <FontAwesomeIcon icon={faTicket} />
        <p className={styles.description}>{blok.description}</p>
      </div>
    )
  }


  return (
    <div className={styles.whatsIncludedItem} {...storyblokEditable(blok as WhatsIncludedItemStoryblok)}>
      <FontAwesomeIcon icon={faTicket} />
      <p className={styles.description}>{(blok as WhatsIncludedItemStoryblok).content}</p>
    </div>
  )
}

export default WhatsIncludedItem