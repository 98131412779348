import React from "react";
import { storyblokEditable } from "@storyblok/react";
import type { FaqStoryblok, FaqItemStoryblok } from "@/app/types/component-types-sb";
import FAQItem from "./FAQItem";

const FAQ = ({ blok }: { blok: FaqStoryblok }) => {
  return (
    <div className='page-section' {...storyblokEditable(blok)}>
      <div className="container">
        <h2 className="section-header">{blok.title}</h2>
        <ul>
          {blok.faqs?.map((item: FaqItemStoryblok) => (
            <FAQItem blok={item} key={item._uid} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default FAQ;