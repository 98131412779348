import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Image from '@/app/components/globals/image';
import type { MediumSliderSlideStoryblok } from "@/app/types/component-types-sb";
import styles from './MediumSlider.module.css';

const MediumSliderSlide = ({ blok }: { blok: MediumSliderSlideStoryblok }) => {
    return (
        <div {...storyblokEditable(blok)} className={styles.slide}>
            {blok.image ?
                <Image asset={blok.image} /> : null
            }
        </div>
    )
}

export default MediumSliderSlide;