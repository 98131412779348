import React from 'react'
import styles from './Header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@awesome.me/kit-d4c82d9167/icons/classic/solid'
import { Article, Category } from '@/app/types/lambda-types'
import { useRouter } from 'next/navigation'

type Props = {
  article?: Article
  category?: Category
}

const SearchFilterDisplay = ({ article, category }: Props) => {
  const router = useRouter()
  return (
    <div onClick={() => article ? router.push(`/knowledge-base/article/${article.uuid}`) : router.push(`/knowledge-base/category/${category?.uuid}`)} className={styles.searchFilterItem}>
      <FontAwesomeIcon icon={faSearch} />
      {article ? (
        <>
          {article.name}
          <p dangerouslySetInnerHTML={{ __html: article.description }} />
        </>
      ) : <>
        {category?.name}
      </>}
    </div>
  )
}

export default SearchFilterDisplay