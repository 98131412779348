import React, { useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@awesome.me/kit-d4c82d9167/icons/classic/light";
import styles from './SearchBar.module.css';

type DateOption = {
  label: string;
  value: string | null;
  fullDate?: string;
};

type DurationType = {
  label: string;
  value: string;
  selected: boolean;
};

type FlexibilityType = {
  label: string;
  value: string;
  selected: boolean;
};

interface DateDropdownProps {
  selectedDate: DateOption;
  onDateChange: (date: DateOption) => void;
  durations: DurationType[];
  flexibility: FlexibilityType[];
  onDurationChange: (duration: string) => void;
  onFlexibilityChange: (flexibility: string) => void;
}

const DateDropdown: React.FC<DateDropdownProps> = ({
  selectedDate,
  onDateChange,
  durations,
  flexibility,
  onDurationChange,
  onFlexibilityChange
}) => {
  const [byMonth, setByMonth] = useState(true);
  const [yearOffset, setYearOffset] = useState(0);
  const currentDate = dayjs();

  const months = [
    { label: 'Jan', value: '01' },
    { label: 'Feb', value: '02' },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04' },
    { label: 'May', value: '05' },
    { label: 'Jun', value: '06' },
    { label: 'Jul', value: '07' },
    { label: 'Aug', value: '08' },
    { label: 'Sep', value: '09' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' }
  ];

  const generateYearPair = () => {
    const startYear = currentDate.year() + yearOffset;
    return [startYear, startYear + 1];
  };

  const years = generateYearPair();
  const isFirstYearSet = yearOffset === 0;
  const isLastYearSet = yearOffset >= 2; // Assuming 4 years total range

  return (
    <div className={styles.dropdownDate}>
      <div className={styles.dropdownDateToggleHeader}>
        <div></div>
        <div className={`${styles.toggleSwitch} ${byMonth ? styles.toggleSwitchOff : styles.toggleSwitchOn}`}>
          <button
            className={`${styles.toggleButton} ${byMonth ? styles.toggleButtonActive : ''}`}
            onClick={() => setByMonth(true)}
          >
            I am flexible
          </button>
          <button
            className={`${styles.toggleButton} ${!byMonth ? styles.toggleButtonActive : ''}`}
            onClick={() => setByMonth(false)}
          >
            Set specific day
          </button>
        </div>
        <div></div>
      </div>

      {byMonth && (
        <>
          <div className={styles.yearChangeButtons}>
            <button
              className={styles.yearChange}
              onClick={() => setYearOffset(prev => prev - 1)}
              disabled={isFirstYearSet}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              className={styles.yearChange}
              onClick={() => setYearOffset(prev => prev + 1)}
              disabled={isLastYearSet}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

          <div className={styles.years}>
            {years.map((year) => (
              <div key={year} className={styles.year}>
                <h3>{year}</h3>
                <div className={styles.months}>
                  {months.map((month) => {
                    const monthValue = `${month.value}_${year}`;
                    const monthDate = dayjs(`${year}-${month.value}-01`);
                    const isDisabled = monthDate.isBefore(currentDate.startOf('month'));

                    return (
                      <button
                        key={monthValue}
                        className={`${styles.month} ${selectedDate.value === monthValue ? styles.monthSelected : ''
                          }`}
                        disabled={isDisabled}
                        onClick={() => onDateChange({
                          label: `${month.label} ${year}`,
                          value: monthValue
                        })}
                      >
                        {month.label}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {!byMonth && (
        <div className={styles.dropdownDateDayCalendar}>
          {/* Day calendar implementation */}
        </div>
      )}

      <div className={styles.advancedSearchInputGroups}>
        <div className={styles.advancedSearchInputGroup}>
          <div className={styles.duration}>
            <select
              onChange={(e) => onDurationChange(e.target.value)}
              defaultValue={durations.find(d => d.selected)?.value}
            >
              {durations.map((duration) => (
                <option
                  key={duration.value}
                  value={duration.value}
                >
                  {duration.label}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.flexibility}>
            <select
              onChange={(e) => onFlexibilityChange(e.target.value)}
              defaultValue={flexibility.find(f => f.selected)?.value}
            >
              {flexibility.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateDropdown;