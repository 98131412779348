import styles from './DestinationHightlight.module.css';
import { storyblokEditable } from "@storyblok/react/rsc"
import { DestinationHighlightsStoryblok } from '@/app/types/component-types-sb';
import PageSection from '../../globals/page_section';
import DestinationHightlight from './destination_highlight';
import HorizontalScrollView from '../../globals/carousels/custom_carousel';

const DestinationHightlights = ({ blok }: { blok: DestinationHighlightsStoryblok }) => {
    return (
        <div {...storyblokEditable(blok)}>
            <PageSection>
                <h2 className='section-header'>{blok.header}</h2>
                <HorizontalScrollView className={styles.horizontalScroll}>
                    {blok.slides.map((slide) => (
                        <DestinationHightlight key={slide._uid} blok={slide} />
                    ))}
                </HorizontalScrollView>
            </PageSection>
        </div>
    )
}

export default DestinationHightlights