import React from 'react'
import styles from '../ThingsToDo.module.css'
import { storyblokEditable } from "@storyblok/react/rsc"
import { ThingsToDoItemStoryblok } from '@/app/types/component-types-sb'
import { render } from 'storyblok-rich-text-react-renderer';
import Image from '@/app/components/globals/image'
import CustomLink from '@/app/components/globals/link';
import clsx from 'clsx';

type ThingsToDoItemProps = {
  blok?: ThingsToDoItemStoryblok,
  height?: "tall" | "short" | "",
  items?: {
    background_image: string,
    description: string[],
    url?: string,
    title: string
  }
}

const ThingsToDoItem = ({ blok, height, items }: ThingsToDoItemProps) => {
  if (blok) {
    return (
      <div {...storyblokEditable(blok)}>
        <div className={clsx(styles.thingsToDoItem, {
          [styles.thingsToDoItemShort]: height === 'short'
        })}>
          <Image
            asset={blok.background_image || ''}
            size={{
              width: 460,
              height: height === 'short' ? 490 : 690
            }}
          />
          <div className={styles.imageBackground} />
          <h1 className={styles.title}>{blok.title}</h1>
          <div className={styles.content}>
            <h1 className={styles.contentTitle}>{blok.title}</h1>
            <div className={clsx(styles.description, {
              [styles.descriptionShort]: height === 'short'
            }
            )}>
              {render(blok.description)}
            </div>
          </div>
          {(blok.url && blok.url.cached_url.trim().length > 0) && (
            <div className={styles.button}>
              <CustomLink
                override_label='Discover More'
                link={blok.url}
                storyBlokLink
                asButton
                variant='outline'
                color={'white'}
              />
            </div>
          )}
        </div>
      </div>
    )
  }


  if (items) {
    return (
      <div>
        <div className={clsx(styles.thingsToDoItem, {
          [styles.thingsToDoItemShort]: height === 'short'
        })}>
          <Image
            asset={items.background_image || ''}
            size={{
              width: 460,
              height: height === 'short' ? 490 : 690
            }}
          />
          <div className={styles.imageBackground} />
          <h1 className={styles.title}>{items.title}</h1>
          <div className={styles.content}>
            <h1 className={styles.contentTitle}>{items.title}</h1>
            <div className={clsx(styles.description, {
              [styles.descriptionShort]: height === 'short'
            }
            )}>
              {items.description.map((description: string) => (
                <p key={description}>{description}</p>
              ))}
            </div>
          </div>
          {(items.url && items.url.trim().length > 0) && (
            <div className={styles.button}>
              <CustomLink
                override_label='Discover More'
                href={items.url}
                storyBlokLink
                asButton
                variant='outline'
                color={'white'}
              />
            </div>
          )}
        </div>
      </div>
    )
  }


}

export default ThingsToDoItem