import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Image from '@/app/components/globals/image';
import type { LargeSliderSlideStoryblok } from "@/app/types/component-types-sb";
import styles from './LargeSlider.module.css';

const LargeSliderSlide = ({ blok }: { blok: LargeSliderSlideStoryblok }) => {
    return (
        <div {...storyblokEditable(blok)} className={styles.slide}>
            {blok.image ?
                <Image asset={blok.image} style={{
                    transform: 'scale(1.2)'
                }} className="embla__parallax__img embla__slide__img" /> : null
            }
        </div>
    )
}

export default LargeSliderSlide;