import { KnowledgeBaseHeaderStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import styles from "./Header.module.css"
import React from 'react'
import Image from '@/app/components/globals/image';
import Container from '@/app/components/globals/container';


const ShortHeader = ({ blok }: { blok: KnowledgeBaseHeaderStoryblok }) => {

    return (
      <div {...storyblokEditable(blok)} >
        <div className={styles.main}>
          <div className={styles.imageWrapperShort}>
            {blok.background_image ?
              <Image alt={blok.title} lazyLoad={false} asset={blok.background_image} /> : null
            }
          </div>
          <div className={styles.overlay} />
          <Container className={styles.container}>
            <div/>
          </Container>
        </div>
      </div>
    )
}

export default ShortHeader