import { ShipTileStoryblok } from "@/app/types/component-types-sb";
import CustomLink from "../../globals/link";
import Image from '@/app/components/globals/image';
import styles from './FleetGrid.module.css';

type Props = {
  ship: ShipTileStoryblok;
}

const ShipTile = ({ ship }: Props) => {
  return (
    <CustomLink
      link={ship.link}
      storyBlokLink
      className={styles.ship}
    >
      <Image asset={ship.image} />
      <div className={styles.darkGradientOverlay}></div>
      <div className={styles.name}>
        {ship.name}
      </div>
    </CustomLink>
  )
}

export default ShipTile;