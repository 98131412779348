import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faLocationDot,
  faShip,
  faChevronDown,
  faSearch,
  faUser,
  faUserFriends,
  faUsers
} from "@awesome.me/kit-d4c82d9167/icons/classic/light";

import DestinationDropdown from './DestinationDropdown';
import LineShipDropdown from './LineShipDropdown';
import DateDropdown from './DateDropdown';
import styles from './SearchBar.module.css';

type Destination = {
  label: string;
  value: string;
  selected?: boolean;
};

type CruiseLine = {
  label: string;
  value: string;
  selected?: boolean;
};

type Ship = {
  label: string;
  value: string;
  group: number;
  selected?: boolean;
};

type Port = {
  label: string;
  value: string | null;
  selected?: boolean;
};

type DateOption = {
  label: string;
  value: string | null;
  fullDate?: string;
};

type PassengerConfig = {
  adults: number;
  children: number;
  childAges: number[];
  maxPassengers: number;
  minAdults: number;
  minChildren: number;
  minChildAge: number;
  maxChildAge: number;
};

type SearchOptions = {
  destination: Destination;
  line: CruiseLine;
  ship: Ship | null;
  date: DateOption;
  passengers: PassengerConfig;
  duration: string;
  flexibility: string;
  regionid: string | null;
  lineid: string | null;
  monthyear: string | null;
  startingPort: Port | null;
  visitingPort: Port | null;
  formno: number;
  perpage: number;
  product: string;
  sid: number;
  sortby: string;
};

interface SearchOptionProps {
  icon: any;
  label: string;
  value: string;
  onClick: () => void;
  isOpen: boolean;
  required?: boolean;
}

const SearchBar: React.FC = () => {
  const initialSearchOptions: SearchOptions = {
    destination: { label: 'Any Destination', value: '' },
    line: { label: 'Any Cruise Line', value: '' },
    ship: null,
    date: { label: 'Select a Date*', value: null },
    passengers: {
      adults: 2,
      children: 0,
      childAges: [1, 1, 1],
      maxPassengers: 4,
      minAdults: 1,
      minChildren: 0,
      minChildAge: 1,
      maxChildAge: 11
    },
    duration: '0',
    flexibility: '3',
    regionid: null,
    lineid: null,
    monthyear: null,
    startingPort: null,
    visitingPort: null,
    formno: 1,
    perpage: 20,
    product: 'cruise',
    sid: 8218,
    sortby: 'departdate'
  };

  const [selectedOptions, setSelectedOptions] = useState<SearchOptions>(initialSearchOptions);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [smartCode, setSmartCode] = useState('');
  const [isSmartCodeValid, setIsSmartCodeValid] = useState(false);

  const destinations: Destination[] = [
    { label: "Any Destination", value: "" },
    { label: "Mediterranean", value: "7" },
    { label: "Caribbean", value: "2" },
    { label: "Far East", value: "5" },
    { label: "Transatlantic", value: "11" },
    { label: "Alaska", value: "13" },
    { label: "Dubai & Emirates", value: "23" },
    { label: "South America", value: "10" },
    { label: "Africa", value: "17" },
    { label: "Australasia", value: "14" },
    { label: "Baltic", value: "20" },
    { label: "Black Sea", value: "21" },
    { label: "Canaries", value: "1" },
    { label: "Central America", value: "3" },
    { label: "China", value: "24" },
    { label: "Europe", value: "4" },
    { label: "Egypt & Red Sea", value: "25" },
    { label: "Hawaii", value: "6" },
    { label: "Iberia", value: "22" },
    { label: "Middle East", value: "19" },
    { label: "North America", value: "8" },
    { label: "Pacific", value: "15" },
    { label: "Polar Regions", value: "18" },
    { label: "Scandinavia", value: "9" },
    { label: "United Kingdom", value: "16" },
    { label: "Worldwide", value: "12" }
  ];

  const cruiseLines: CruiseLine[] = [
    { label: "Any Cruise Line", value: "" },
    { label: "MSC", value: "16" },
    { label: "Norwegian", value: "17" },
    { label: "Princess", value: "20" },
    { label: "Virgin Voyages", value: "734" },
    { label: "Costa", value: "9" },
    { label: "Celebrity", value: "3" },
    { label: "Royal Caribbean", value: "22" },
    { label: "Carnival", value: "8" },
    { label: "Holland America", value: "15" },
    { label: "AmaWaterways", value: "63" },
    { label: "Ambassador", value: "848" },
    { label: "Azamara", value: "66" },
    { label: "Celestyal", value: "495" },
    { label: "Crystal", value: "10" },
    { label: "Cunard", value: "5" },
    { label: "Disney", value: "73" },
    { label: "Explora Journeys", value: "849" },
    { label: "Fred Olsen", value: "13" },
    { label: "Marella", value: "28" },
    { label: "Oceania", value: "48" },
    { label: "P&O", value: "1" },
    { label: "Regent Seven Seas", value: "21" },
    { label: "Seabourn", value: "24" },
    { label: "Silversea", value: "25" }
  ];

  const ships: Ship[] = [
    { label: "Any Ship", value: "", group: -1 },
    // Add your ships data here
  ];

  const startingPorts: Port[] = [
    { label: "Any Port", value: null },
    // Add your starting ports data here
  ];

  const visitingPorts: Port[] = [
    { label: "Any Port", value: null },
    // Add your visiting ports data here
  ];

  const durations = [
    { label: 'Any Duration', value: '0', selected: true },
    { label: '1-5 Nights', value: '1', selected: false },
    { label: '6-10 Nights', value: '2', selected: false },
    { label: '11-16 Nights', value: '3', selected: false },
    { label: '17-30 Nights', value: '4', selected: false },
    { label: '31 Nights+', value: '5', selected: false }
  ];

  const flexibility = [
    { label: 'Days +/-', value: '3', selected: true },
    { label: '0 Days', value: '0', selected: false },
    { label: '1 Day', value: '1', selected: false },
    { label: '2 Days', value: '2', selected: false },
    { label: '3 Days', value: '3', selected: false },
    { label: '4 Days', value: '4', selected: false },
    { label: '5 Days', value: '5', selected: false }
  ];

  const SearchOption: React.FC<SearchOptionProps> = ({
    icon,
    label,
    value,
    onClick,
    isOpen,
    required
  }) => (
    <div className={`${styles.option} ${isOpen ? styles.optionOpen : ''}`}>
      <button onClick={onClick} className={styles.optionButton}>
        <span className={styles.optionLabel}>
          <FontAwesomeIcon icon={icon} className={styles.optionIcon} />
          <span className={styles.optionText}>
            <span className={styles.optionLabelText}>
              {label}{required && '*'}
            </span>
            <span className={styles.optionLabelValue}>{value}</span>
          </span>
        </span>
        <span className={`${styles.optionChevron} ${isOpen ? styles.chevronOpen : ''}`}>
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </button>
    </div>
  );

  const validateSmartCode = (code: string): boolean => {
    return code.length === 11 && /[A-Z]{3}\d{7}[CIS]/.test(code);
  };

  const handleSmartCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase().replace(' ', '');
    setSmartCode(value);
    setIsSmartCodeValid(validateSmartCode(value));
  };

  const handleDestinationChange = (destination: Destination) => {
    setSelectedOptions(prev => ({
      ...prev,
      destination,
      regionid: destination.value
    }));
    setOpenDropdown(null);
  };

  const handleLineChange = (line: CruiseLine) => {
    setSelectedOptions(prev => ({
      ...prev,
      line,
      lineid: line.value,
      ship: null // Reset ship when line changes
    }));
  };

  const handleShipChange = (ship: Ship) => {
    setSelectedOptions(prev => ({
      ...prev,
      ship
    }));
  };

  const handleDateChange = (date: DateOption) => {
    setSelectedOptions(prev => ({
      ...prev,
      date,
      monthyear: date.value
    }));
  };

  const handleSearch = () => {
    if (!selectedOptions.date.value) {
      setOpenDropdown('date');
      return;
    }

    const searchParams = new URLSearchParams();
    Object.entries(selectedOptions).forEach(([key, value]) => {
      if (value !== null && typeof value !== 'object') {
        searchParams.append(key, value.toString());
      }
    });

    window.location.href = `/search-results?${searchParams.toString()}`;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openDropdown && !(event.target as Element).closest(`.${styles.searchBar}`)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [openDropdown]);

  return (
    <div className="container mx-auto">
      <div className={styles.advancedRegion}>
        {openDropdown && (
          <div className={styles.dropdownExit} onClick={() => setOpenDropdown(null)} />
        )}

        <div className={`${styles.searchBar} ${openDropdown ? styles.searchBarOpen : ''}`}>
          <div className={styles.options}>
            <SearchOption
              icon={faLocationDot}
              label="Destination"
              value={selectedOptions.destination.label}
              onClick={() => setOpenDropdown(openDropdown === 'destination' ? null : 'destination')}
              isOpen={openDropdown === 'destination'}
            />
            <SearchOption
              icon={faShip}
              label="Cruise Line"
              value={selectedOptions.line.label}
              onClick={() => setOpenDropdown(openDropdown === 'line' ? null : 'line')}
              isOpen={openDropdown === 'line'}
            />
            <SearchOption
              icon={faCalendarDay}
              label="Date"
              value={selectedOptions.date.label}
              onClick={() => setOpenDropdown(openDropdown === 'date' ? null : 'date')}
              isOpen={openDropdown === 'date'}
              required
            />

            {openDropdown && (
              <div className={styles.dropdown}>
                {openDropdown === 'destination' && (
                  <DestinationDropdown
                    destinations={destinations}
                    startingPorts={startingPorts}
                    visitingPorts={visitingPorts}
                    selectedDestination={selectedOptions.destination}
                    onDestinationChange={handleDestinationChange}
                  />
                )}

                {openDropdown === 'line' && (
                  <LineShipDropdown
                    cruiseLines={cruiseLines}
                    ships={ships}
                    selectedLine={selectedOptions.line}
                    selectedShip={selectedOptions.ship}
                    onLineChange={handleLineChange}
                    onShipChange={handleShipChange}
                  />
                )}

                {openDropdown === 'date' && (
                  <DateDropdown
                    selectedDate={selectedOptions.date}
                    onDateChange={handleDateChange}
                    durations={durations}
                    flexibility={flexibility}
                    onDurationChange={(duration) =>
                      setSelectedOptions(prev => ({ ...prev, duration }))
                    }
                    onFlexibilityChange={(flexibility) =>
                      setSelectedOptions(prev => ({ ...prev, flexibility }))
                    }
                  />
                )}
              </div>
            )}
          </div>

          <button onClick={handleSearch} className={styles.searchButton}>
            <FontAwesomeIcon icon={faSearch} className="mr-2" />
            SEARCH
          </button>
        </div>

        <div className={styles.extras}>
          <div className={`${styles.extrasSection} ${isSmartCodeValid ? styles.extrasSectionValid : ''}`}>
            <input
              type="text"
              maxLength={11}
              className={`${styles.smartCode} ${smartCode ? styles.smartCodeHasContent : ''}`}
              placeholder="Search by Smart Code"
              value={smartCode}
              onChange={handleSmartCodeChange}
            />
            <button
              className={styles.smartCodeButton}
              disabled={!isSmartCodeValid}
            >
              LOOK-UP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;