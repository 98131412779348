import React from 'react'

import styles from './ContentSplitTwoImages.module.css'

import { AssetStoryblok, ButtonStoryblok, LinkStoryblok, RichtextStoryblok } from '@/app/types/component-types-sb'
import Image from '../../globals/image'
import Button from '../../globals/button'
import CustomLink from '../../globals/link'
import { render } from 'storyblok-rich-text-react-renderer'
import clsx from 'clsx'

type Props = {
  flip?: boolean,
  title?: string,
  textContent: RichtextStoryblok,
  image: AssetStoryblok,
  imageTitle?: string,
  imageDecription?: string,
  button?: (ButtonStoryblok | LinkStoryblok)[],
  wideImage?: boolean
}

const ContentSplitTwoImageTextImage = ({
  flip,
  title,
  textContent,
  image,
  imageTitle,
  imageDecription,
  button,
  wideImage
}: Props) => {


  const scrollToGroups = () => {
    const groups = document.getElementsByClassName('story-blokgroups')[0]

    if (!groups) return

    const offset = 100;
    const elementTop = groups.getBoundingClientRect().top + window.scrollY;
    const targetPosition = elementTop - offset;

    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  }

  return (
    <div className={clsx(flip ? styles.containerFlipped : styles.container, 'container', 'container--expanded')}>
      <div className={styles.textContent}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.text}>
          {render(textContent)}
        </div>
        {(button && (button.length > 0)) && (
          <>
            {(button[0].component === 'button') ? (
              <Button onClick={scrollToGroups} variant='outline'>
                {button[0].label}
              </Button>
            ) : (
              <CustomLink
                link={button[0].url}
                variant='outline'
                asButton
              />
            )}
          </>
        )}
      </div>
      <div className={styles.imageContent}>
        <div className={clsx(styles.imageContainer, wideImage ? styles.imageContainerWide : '')}>
          <Image asset={image}
            size={{ width: wideImage ? 1000 : 800, height: 500 }}
          />
        </div>
      </div>
    </div>
  )
}

export default ContentSplitTwoImageTextImage;
