import { WhatsIncludedItemStoryblok, WhatsIncludedSectionStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React, { useEffect, useState } from 'react'
import PageSection from '../../globals/page_section';
import styles from './WhatsIncluded.module.css';
import Container from '../../globals/container';
import { getWhatsIncluded } from '@/utils/queries';
import { WhatsIncludedType } from '@/app/types/lambda-types';
import WhatsIncludedItem from './WhatsIncludedItem';

const WhatsIncluded = ({ blok }: { blok: WhatsIncludedSectionStoryblok }) => {

  const [whatsIncluded, setWhatsIncluded] = useState<WhatsIncludedType[] | WhatsIncludedItemStoryblok[]>([])

  useEffect(() => {

    if(blok.enter_manually){
      setWhatsIncluded(blok.items || [])
      return;
    }

    const fetchData = async () => {
      const response = await getWhatsIncluded(
        {
          cruise_line_tt_id: parseInt(blok.cruise_lines || '1'),
          has_flights: blok.has_flights ? 1 : 0,
          has_hotels: blok.has_hotels ? 1 : 0
        }
      )
      console.log(response)
      setWhatsIncluded(response)
    }

    fetchData()
  }, [])

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h1 className={styles.title}>{blok.title}</h1>
          <div className={styles.whatsIncluded}>
            {whatsIncluded.map((item, index) => (
              <WhatsIncludedItem blok={item} key={index} />
            ))}
          </div>
        </Container>
      </PageSection>
    </div>
  )
}

export default WhatsIncluded