import React, { useState } from 'react'
import styles from '../DestinationHightlight.module.css';
import { storyblokEditable } from "@storyblok/react/rsc"
import { DestinationHighlightStoryblok } from '@/app/types/component-types-sb';
import { render } from 'storyblok-rich-text-react-renderer';
import Image from '@/app/components/globals/image';
import Button from '@/app/components/globals/button';
import {
    AlertDialog,
    AlertDialogTrigger,
    AlertDialogContent,
} from '@/app/components/globals/alert-dialog/AlertDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@awesome.me/kit-d4c82d9167/icons/classic/regular"

const DestinationHightlight = ({ blok }: { blok: DestinationHighlightStoryblok }) => {
    const [showDialog, setShowDialog] = useState(false);
    return (
        <div {...storyblokEditable(blok)}>
            <div className={styles.highlight}>
                <div className={styles.highlightImageHeader}>
                    <Image className={styles.higlightBackgroundImage} asset={blok.background_image ?? ''} size={{
                        width: 350,
                        height: 350
                    }} />
                </div>
                <h1 className={styles.highlightTitle}>{blok.title}</h1>
                <p className={styles.subTitle}>{blok.sub_title}</p>
                <div className={styles.description}>
                    {render(blok.description)}
                </div>
                <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
                    <AlertDialogTrigger asChild>
                        <Button variant='outline' className={styles.highlightButton}>
                            Read More
                        </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent showBackdrop={false} >
                        <div className={styles.alertDialogContent}>
                            <div className={styles.alertDialogMain}>
                                <div className={styles.highlightImage}>
                                    <Image className={styles.higlightBackgroundOverlay} asset={blok.background_image ?? ''} size={{
                                        width: 1280,
                                        height: 720
                                    }} />
                                </div>
                                <div className={styles.overlay} />

                                <div className={styles.alertDialogInformation}>
                                    <h1 className={styles.highlightDialogTitle}>{blok.title}</h1>
                                    <p className={styles.alertSubTitle}>{blok.sub_title}</p>
                                    <div className={styles.alertDescription}>
                                        {render(blok.description)}
                                    </div>
                                </div>
                                <Button variant='transparent' onClick={() => setShowDialog(false)} className={styles.alertDialogClose}>
                                    <FontAwesomeIcon size='3x' icon={faXmark} className={styles.alertDialogCloseIcon} />
                                </Button>
                            </div>
                        </div>
                    </AlertDialogContent>
                </AlertDialog>
            </div>
        </div>
    )
}

export default DestinationHightlight