import React, { useEffect, useState, useRef, FC, HTMLAttributes } from 'react'
import styles from './TextField.module.css'
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@awesome.me/kit-d4c82d9167/icons';

type Props = {
  placeholder?: string;
  searchable?: boolean;
  onSearch?: (value: string) => void;
  onChange?: (value: string) => void;
  onSearchSelect?: (value: string) => void;
  options?: React.ReactNode[];
  icon?: IconDefinition
  className?: string
}

const TextField: FC<Props & HTMLAttributes<HTMLInputElement>> = ({
  placeholder = 'Search...',
  searchable = false,
  onSearch,
  onChange,
  onSearchSelect,
  options = [],
  icon,
  className,
  ...props
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   if (searchable) {
  //     setFilteredOptions(
  //       options.filter((option) =>
  //         option.toLowerCase().includes(searchValue.toLowerCase())
  //       )
  //     );
  //   }
  // }, [searchable, searchValue, options]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    setIsDropdownOpen(options.length > 0);
    if (searchable) {
      onSearch?.(value);
    }else{
      onChange?.(value);
    }
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target as Node) &&
      isDropdownOpen
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isDropdownOpen]);

  return (
    <div className={clsx(styles.searchableInputContainer, className)}>
      <div className={styles.searchableInputMain}>
        {icon && (<FontAwesomeIcon icon={icon} />)}
        <input
          ref={inputRef}
          type="text"
          value={searchValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          className={styles.searchableInput}
          {...props}
        />
      </div>

      {searchable && isDropdownOpen && (
        <ul className={styles.searchableDropdown}>
          {options.map((option, index) => (
            <li
              key={index}
              className={styles.searchableDropdownItem}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TextField