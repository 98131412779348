import { ContentTextImageSurroundStoryblok } from '@/app/types/component-types-sb'
import styles from './ContentTextImageSurround.module.css'

import React from 'react'
import Image from '../../globals/image'
import { render } from 'storyblok-rich-text-react-renderer'
import { storyblokEditable } from '@storyblok/react/rsc'
import clsx from 'clsx'

const ContentTextImageSurround = ({ blok }: { blok: ContentTextImageSurroundStoryblok }) => {
  return (
    <div className='page-section' {...storyblokEditable(blok)}>
      <div className='container'>
        <div className={clsx(styles.topSection, blok.flip ? styles.topSectionFlipped : null)}>
          <div className={styles.topImage}>
            <Image asset={blok.image_side}
              size={{ width: 500, height: 700 }}
            />
          </div>
          <div className={styles.textSection}>
            {render(blok.text_content)}
          </div>
        </div>

        <div className={clsx(styles.bottomSection, blok.flip ? styles.bottomSectionFlipped : null)}>
          <div className={styles.bottomSideImage}>
            <Image asset={blok.image_bottom_side}
              size={{ width: 800, height: 300 }}
            />
          </div>
          <div className={styles.bottomImage}>
            <Image asset={blok.image_bottom}
              size={{ width: 480, height: 270 }}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default ContentTextImageSurround