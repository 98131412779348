"use client"
import React from "react"
import Link from "next/link"
import Image from "next/image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './Navigation.module.css'
import { faCreditCardFront, faHeadset, faEnvelopeDot } from "@awesome.me/kit-d4c82d9167/icons/classic/regular"
import Container from "../container"
import clsx from "clsx"
import CustomLink from "../link"
import Button from "../button"

type Props = {
  isVisible: boolean,
  children: React.ReactNode
}

const NavigationParent = ({ isVisible, children }: Props) => {
  return (
    <div className={clsx(
      styles.navigationWrapper,
      {
        [styles.navigationWrapperOpen]: !isVisible
      }
    )}>
      <div className={clsx(styles.topBar, { [styles.topColorChange]: !isVisible })}>
        <Container>
          <div className={styles.topBarContainer}>
            <button onClick={()=>{
              (document.getElementById("signup-modal") as HTMLDialogElement)?.showModal()
            }} className={styles.topBarButton}>
            <FontAwesomeIcon icon={faEnvelopeDot} /> Get Exclusive Offers
            </button>
            <Link href="/knowledge-base">
              <FontAwesomeIcon icon={faHeadset} /> Customer Support
            </Link>
            <Link href="/make-payment">
              <FontAwesomeIcon icon={faCreditCardFront} /> Make Payment
            </Link>
          </div>
        </Container>
      </div>
      <div className={styles.navigation}>
        <Container>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '200px',
            height: '72px'
          }}>
            <CustomLink href="/">
              <Image
                width={250}
                height={49}
                className={styles.logo}
                src="https://a.storyblok.com/f/306333/300x52/9ee8688c42/cn-2024-red-circle.svg"
                alt="Cruise Nation Logo"
              />
            </CustomLink>
            {children}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default NavigationParent