import React from "react";
import type { VimeoVideoStoryblok } from "@/app/types/component-types-sb";
import styles from './VimeoVideo.module.css';

/**
 * A component for rendering a URL video
 *
 * @param {VimeoVideoStoryblok} blok - The Storyblok data for the component
 * @returns {React.ReactElement} The rendered component
 */
const VideoVimeo = ({ blok }: { blok: VimeoVideoStoryblok }) => {
  return (
    <div className={styles.wrapper}>
      <iframe
        src={blok.url}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen={true}
        className={styles.iframe}></iframe>
    </div>
  )
}

export default VideoVimeo;