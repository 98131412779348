import { KnowledgeBaseHeaderStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import ShortHeader from './ShortHeader';
import FullHeader from './FullHeader';

const KnowledgeBaseHeader = ({ blok }: { blok: KnowledgeBaseHeaderStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.style === '2' ? (
        <ShortHeader blok={blok} />
      ) : (
        <FullHeader blok={blok} />
      )
      }
    </div>
  );
}

export default KnowledgeBaseHeader