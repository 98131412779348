/** 1. Tag it as a client component */
"use client"
import { storyblokInit, apiPlugin } from "@storyblok/react";
import { ReactNode } from "react";

import components from "./globalComponents";

/** 2. Initialize it as usual */
storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY,
    use: [apiPlugin],
    apiOptions: {
        region: 'eu'
    },
    components
});

type Props = { children: ReactNode };
const StoryblokProvider = ({ children }: Props) => {
    return children;
};

export default StoryblokProvider;
