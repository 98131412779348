import React, { useRef } from "react";
import { storyblokEditable } from "@storyblok/react";
import type { LargeSliderSlideStoryblok, LargeSliderStoryblok } from "@/app/types/component-types-sb";
import LargeSliderSlide from "./LargeSliderSlide";
import { EmblaOptionsType } from 'embla-carousel'
import ParalaxCarousel from "../../globals/carousels/parallax";

const OPTIONS: EmblaOptionsType = { dragFree: true, loop: true };

const LargeSlider = ({ blok }: { blok: LargeSliderStoryblok }) => {

  return (
    <div {...storyblokEditable(blok)} className="page-section">
      <div className="medium-slider">
        <ParalaxCarousel options={OPTIONS} >
          {
            blok.slides?.map((slide: LargeSliderSlideStoryblok) => (
              <LargeSliderSlide blok={slide} key={slide._uid} />
            ))
          }
        </ParalaxCarousel>
      </div>
    </div>
  )
}

export default LargeSlider;