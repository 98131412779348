import { HTMLAttributes } from 'react';
import styles from './Table.module.css';

type TableProps = {
  className?: string;
}

export function Table({ 
  className = "", 
  ...props 
}: TableProps & HTMLAttributes<HTMLTableElement>) {
  return (
    <table
      className={`${styles['table']} ${className}`}
      {...props}
    />
  );
}

type TableHeaderProps = {
  className?: string;
}

export function TableHeader({ 
  className = "", 
  ...props 
}: TableHeaderProps & HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <thead
      className={`${styles['table-header']} ${className}`}
      {...props}
    />
  );
}

type TableBodyProps = {
  className?: string;
}

export function TableBody({ 
  className = "", 
  ...props 
}: TableBodyProps & HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tbody
      className={`${styles['table-body']} ${className}`}
      {...props}
    />
  );
}

type TableFooterProps = {
  className?: string;
}

export function TableFooter({ 
  className = "", 
  ...props 
}: TableFooterProps & HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tfoot
      className={`${styles['table-footer']} ${className}`}
      {...props}
    />
  );
}

type TableRowProps = {
  className?: string;
}

export function TableRow({ 
  className = "", 
  ...props 
}: TableRowProps & HTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr
      className={`${styles['table-row']} ${className}`}
      {...props}
    />
  );
}

type TableHeadProps = {
  className?: string;
}

export function TableHead({ 
  className = "", 
  ...props 
}: TableHeadProps & HTMLAttributes<HTMLTableCellElement>) {
  return (
    <th
      className={`${styles['table-head']} ${className}`}
      {...props}
    />
  );
}

type TableCellProps = {
  className?: string;
}

export function TableCell({ 
  className = "", 
  ...props 
}: TableCellProps & HTMLAttributes<HTMLTableCellElement>) {
  return (
    <td
      className={`${styles['table-cell']} ${className}`}
      {...props}
    />
  );
}

type TableCaptionProps = {
  className?: string;
}

export function TableCaption({ 
  className = "", 
  ...props 
}: TableCaptionProps & HTMLAttributes<HTMLTableCaptionElement>) {
  return (
    <caption
      className={`${styles['table-caption']} ${className}`}
      {...props}
    />
  );
}