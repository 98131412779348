import React from 'react';
import type { FleetStoryblok, ShipTileStoryblok } from "@/app/types/component-types-sb";
import { storyblokEditable } from '@storyblok/react';
import styles from './FleetGrid.module.css';
import ShipTile from './ShipTile';

const Fleet = ({ blok }: { blok: FleetStoryblok }) => {
  return (
    <div className='page-section' {...storyblokEditable(blok)}>
      <div className='container container--expanded'>
        <h2 className='section-header'>{blok.title}</h2>
        <div className={styles.ships}>
          {blok.ships.map((ship: ShipTileStoryblok) => (
            <ShipTile ship={ship} key={ship._uid} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Fleet;