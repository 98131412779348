import React from 'react'
import styles from './ThingsToDo.module.css'
import { ThingsToDoStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc"
import PageSection from '../../globals/page_section'
import HorizontalScrollView from '../../globals/carousels/custom_carousel'
import ThingsToDoItem from './things_to_do_item'

const ThingsToDo = ({ blok }: { blok: ThingsToDoStoryblok }) => {
  return (
    <PageSection>
      <div {...storyblokEditable(blok)}>
        {
          blok.header ?
            <h2 className='section-header'>{blok.header}</h2>
            : null
        }
        <HorizontalScrollView className={styles.horizontalScroll}>
          {blok.slides.map((slide) => (
            <ThingsToDoItem key={slide._uid} height={blok.height} blok={slide} />
          ))}
        </HorizontalScrollView>
      </div>
    </PageSection>
  )
}

export default ThingsToDo