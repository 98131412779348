import React from "react";
import Image from '@/app/components/globals/image';
import { AssetStoryblok } from "@/app/types/component-types-sb";

/**
 * A function to render a holiday type icon based on the provided type.
 *
 * @param type - The type of holiday. It can be either 'cruiseonly' or other.
 * @returns A React component displaying the corresponding holiday type icon.
 */
const TypeIcon = ({ type, width, height }: { type: string, width?: number, height?: number }) => {
  return (
    <div style={{
      width: width ? width + 'px' : '58px',
      minWidth: width ? width + 'px' : '58px',
      height: height ? height + 'px' : '64px',
      minHeight: height ? height + 'px' : '64px',
      position: 'relative'
    }}>
      {type === 'cruiseonly' ?
        <Image style={{ objectFit: 'contain' }} asset={{
          id: 0,
          name: 'cruise',
          filename: 'https://a.storyblok.com/f/306333/78x78/32939ad27a/cruise-only-waves-labled.png',
          alt: 'Cruise Only Holiday Icon'
        } as AssetStoryblok} /> :
        <Image style={{ objectFit: 'contain' }} asset={{
          id: 1,
          name: 'smart',
          filename: 'https://a.storyblok.com/f/306333/106x116/b89017374d/smart-holiday-icon-2023.png',
          alt: 'Smart Package Holiday Icon'
        } as AssetStoryblok} />
      }
    </div>
  )
}

export default TypeIcon;