'use client'
import { LinkColumnStoryblok, NavigationDropdownStoryblok } from '@/app/types/component-types-sb'
import styles from './Navigation.module.css';
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import DropdownGridItem from './DropdownGridItem';
import Button from '../../globals/button';
import { useClickOutside } from '@/utils/hooks/click-outside';
import Container from '../../globals/container';
import CustomLink from '../../globals/link';

type Props = {
  dropdown: NavigationDropdownStoryblok
}

const Dropdown = ({ dropdown }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const pathname = usePathname();

  useClickOutside(wrapperRef, () => setIsOpen(false))

  useEffect(() => {
    setIsOpen(false)
  }, [pathname]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div>
      <button onClick={toggleDropdown} className={
        clsx(styles.dropdownButton, (isOpen === true) && styles.dropdownButtonSelected)
      }>
        <p className={styles.dropdownLabel}>{dropdown.label}</p>
      </button>
      <div
        className={clsx(
          styles.dropdownContainer,
          (isOpen === true) && styles.open
        )}
      >
        <Container>
          <div className={styles.innerContainer} ref={wrapperRef}>
            <p className={styles.dropdownHeader}>{dropdown.header_text} <span>{dropdown.sub_header_text}</span></p>

            <div className={styles.dropDownGrid}>
              {dropdown.link_columns.map((item: LinkColumnStoryblok) => (
                <DropdownGridItem key={item._uid} item={item} showImage={true} />
              ))}
            </div>
            <hr style={{
              marginTop: '15px',
              marginBottom: '15px'
            }} />
            <div className={styles.dropDownGrid}>
              {dropdown.link_columns.map((item: LinkColumnStoryblok) => (
                <DropdownGridItem key={item._uid} item={item} showImage={false} />
              ))}
            </div>
            <hr style={{
              marginTop: '15px',
              marginBottom: '15px'
            }} />
            <CustomLink
              override_label={dropdown.view_all_link_label_override}
              link={dropdown.view_all_link}
              asButton
              storyBlokLink
              variant='primary'
              className={styles.dropdownButtonLink}
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Dropdown