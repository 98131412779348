import { HolidayExtra } from "@/app/types/lambda-types";

/**
 * Filters and returns a list of unique HolidayExtra items based on type and category.
 *
 * @param {HolidayExtra[]} extras - The array of HolidayExtra items to filter.
 * @param {boolean} [mergeHotels] - Optional flag indicating whether to merge hotel items
 *                                  regardless of category.
 * @returns {HolidayExtra[]} A new array of HolidayExtra items, ensuring uniqueness by type
 *                           and category unless mergeHotels is true, which ignores category
 *                           for hotel type.
 */

const getIncluded = (extras: HolidayExtra[], mergeHotels?: boolean): HolidayExtra[] => {
  const uniqueByCategory = extras.reduce((uniqueItems: HolidayExtra[], currentItem: HolidayExtra) => {
    const existingCategoryItem = uniqueItems.find(item => {
      if (item.type !== 'flight' && !mergeHotels)
        return item.type === currentItem.type && currentItem.category === item.category;
      return item.type === currentItem.type
    });
    if (!existingCategoryItem) {
      return uniqueItems.concat([currentItem]);
    } else {
      return uniqueItems;
    }
  }, []);
  return uniqueByCategory;
}

export default getIncluded;