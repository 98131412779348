import { CategoryViewStoryblok } from "@/app/types/component-types-sb";
import React, { useState, useEffect } from "react";
import styles from "./CategoryView.module.css";
import { storyblokEditable } from "@storyblok/react/rsc";
import PageSection from "@/app/components/globals/page_section";
import Container from "@/app/components/globals/container";
import { useParams } from "next/navigation";
import { getKnowledgeBaseChildrenRoutes } from "@/utils/queries";
import type {
  KnowledgeBaseChildrenRoutes,
} from "@/app/types/lambda-types";
import { Category as CategoryType, Article as ArticleType } from "@/app/types/lambda-types";
import Category from "./Category";
import Article from "./Article";

const CategoryView = ({ blok }: { blok: CategoryViewStoryblok }) => {
  const params = useParams();
  const [response, setResponse] = useState<KnowledgeBaseChildrenRoutes>();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getKnowledgeBaseChildrenRoutes(params.uuid as string);
      setResponse(data);
    };
    fetchData();
  }, []);

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h1>{blok.title}</h1>
          <div className={styles.categories}>
            {response &&
              response.articles.map((article: ArticleType) => (
                <Article article={article} key={article.uuid} />
              ))}
          </div>
          <br />
          <br />
          <div className={styles.categories}>
            {response &&
              response.categories.map((category: CategoryType) => (
                <Category category={category} key={category.uuid} />
              ))}
          </div>
        </Container>
      </PageSection>
    </div>
  );
};

export default CategoryView;
