import styles from './WeatherChart.module.css';
import { WeatherChartStoryblok } from '@/app/types/component-types-sb';
import { storyblokEditable } from '@storyblok/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { PortGuide, RegionWeather } from '@/app/types/lambda-types';
import { getWeatherChartData } from '@/utils/queries';
import clsx from 'clsx';
import { sortAndPrioritizeName } from '@/utils/actions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
);

const WeatherChart = ({ blok }: { blok: WeatherChartStoryblok }) => {
  const [data, setData] = useState<RegionWeather>()
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getWeatherChartData(blok.region_id, search)

      const dataSort = blok.default_port ? sortAndPrioritizeName(response.port_guide || [], "id", blok.default_port || '') : { 
        sortedArray: [], 
        exists: true 
      };

      if (blok.default_port) {
        const dataResponse = await getWeatherChartData(blok.region_id, String((dataSort.sortedArray as PortGuide[])[0].id));

        setData({
          port_guide: dataSort.sortedArray as PortGuide[],
          weather: dataResponse.weather
        })
      }else{
        setData(response)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getWeatherChartData(blok.region_id, search)

      if (data?.port_guide && (data.port_guide.length > 0)) {
        setData({
          ...data,
          weather: response.weather
        })
      } else {
        setData(response)
      }
    }

    fetchData()
  }, [search])

  return (
    <div className={clsx(
      styles.weather_chart,
      {
        [styles.loading_chart]: !data
      }
    )} {...storyblokEditable(blok)}>
      {data ? (
        <>
          <Chart
            type="bar"
            data={{
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              datasets: [
                {
                  type: 'line',
                  label: 'Precipitation Per Month (mm)',
                  data: data?.weather.map((data) => Math.floor(parseFloat(data.precip))),
                  borderColor: '#0bb4ff',
                  backgroundColor: '#0bb4ff',
                  borderWidth: 2,
                  fill: false,
                  tension: 0.2
                },
                {
                  type: 'bar',
                  label: 'Temperature (°C)',
                  data: data?.weather.map((data) => parseInt(data.average_temperature)),
                  backgroundColor: [
                    '#ffa300',
                  ],
                  borderColor: [
                    '#ffa300'
                  ],
                  borderWidth: 1
                },
              ]
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}
          />
          <select onChange={(e) => setSearch(e.target.value)}>
            {data?.port_guide && data?.port_guide.map((data, index) => (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            ))}
          </select>
        </>
      ) : (
        <div className={styles.loading} />
      )}
    </div>
  )
}

export default WeatherChart