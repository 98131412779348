import React, { useCallback, useEffect, useRef } from 'react'
import {
  EmblaCarouselType,
  EmblaEventType,
  EmblaOptionsType
} from 'embla-carousel'
import styles from './Paralax.module.css'
import useEmblaCarousel from 'embla-carousel-react'
// import {
//   NextButton,
//   PrevButton,
//   usePrevNextButtons
// } from './EmblaCarouselArrowButtons'
// import { DotButton, useDotButton } from './EmblaCarouselDotButton'

const TWEEN_FACTOR_BASE = 0.2

type PropType = {
  options?: EmblaOptionsType
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode[] | React.ReactNode
}

/**
 * A carousel component with parallax effect.
 *
 * @prop {Object} options - Options of EmblaCarousel. Refer to the EmblaCarousel documentation for more details.
 * @prop {string} className - Class name of the carousel container.
 * @prop {Object} style - Style of the carousel container.
 * @prop {ReactNode | ReactNode[]} children - The children elements of the carousel. If it is an array, the children will be treated as individual slides.
 *
 * @example
 * <ParalaxCarousel>
 *   <img src="image1.jpg" />
 *   <img src="image2.jpg" />
 *   <img src="image3.jpg" />
 * </ParalaxCarousel>
 */

const ParalaxCarousel: React.FC<PropType> = (props) => {
  const { options, className, style, children } = props
  const [emblaRef, emblaApi] = useEmblaCarousel({
    ...options
  })
  const tweenFactor = useRef(0)
  const tweenNodes = useRef<HTMLElement[]>([])

  // const { selectedIndex, scrollSnaps, onDotButtonClick } =
  //   useDotButton(emblaApi)

  // const {
  //   prevBtnDisabled,
  //   nextBtnDisabled,
  //   onPrevButtonClick,
  //   onNextButtonClick
  // } = usePrevNextButtons(emblaApi)

  const setTweenNodes = useCallback((emblaApi: EmblaCarouselType): void => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector(`.${styles["embla__parallax__layer"]}`) as HTMLElement
    })
  }, [])

  const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
  }, [])

  const tweenParallax = useCallback(
    (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
      const engine = emblaApi.internalEngine()
      const scrollProgress = emblaApi.scrollProgress()
      const slidesInView = emblaApi.slidesInView()
      const isScrollEvent = eventName === 'scroll'

      emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        let diffToTarget = scrollSnap - scrollProgress
        const slidesInSnap = engine.slideRegistry[snapIndex]

        slidesInSnap.forEach((slideIndex) => {
          if (isScrollEvent && !slidesInView.includes(slideIndex)) return

          if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach((loopItem) => {
              const target = loopItem.target()

              if (slideIndex === loopItem.index && target !== 0) {
                const sign = Math.sign(target)

                if (sign === -1) {
                  diffToTarget = scrollSnap - (1 + scrollProgress)
                }
                if (sign === 1) {
                  diffToTarget = scrollSnap + (1 - scrollProgress)
                }
              }
            })
          }

          const translate = diffToTarget * (-1 * tweenFactor.current) * 17.5
          const tweenNode = tweenNodes.current[slideIndex]
          if (!tweenNode) return
          tweenNode.style.transform = `translateX(${translate}%)`
        })
      })
    },
    []
  )

  useEffect(() => {
    if (!emblaApi) return

    setTweenNodes(emblaApi)
    setTweenFactor(emblaApi)
    tweenParallax(emblaApi)

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenParallax)
      .on('scroll', tweenParallax)
      .on('slideFocus', tweenParallax)
  }, [emblaApi, tweenParallax])

  return (
    <div className={`${styles["embla"]} ${className}`} style={style}>
      <div className={styles["embla__viewport"]} ref={emblaRef}>
        <div className={styles["embla__container"]}>
          {Array.isArray(children) ? children.map((child, index) => (
            <div className={styles["embla__slide"]} key={index}>
              <div className={styles["embla__parallax"]}>
                <div className={`${styles["embla__parallax__layer"]} `}>
                  <div
                    className={`${styles["embla__slide__img"]} ${styles["embla__parallax__img"]}`}
                  >
                    {child}
                  </div>
                </div>
              </div>
            </div>
          )) : (
            <div className={styles["embla__slide"]}>
              <div className={styles["embla__parallax"]}>
                <div className={styles["embla__parallax__layer"]}>
                  <div
                    className={`${styles["embla__slide__img"]} ${styles["embla__parallax__img"]}`}
                  >
                    {children}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div> */}
    </div>
  )
}

export default ParalaxCarousel
