import { ShipDetailsStoryblok } from '@/app/types/component-types-sb'
import { storyblokEditable } from "@storyblok/react/rsc";
import React, { useEffect, useState } from 'react'
import { Barsandlounge, ShipDetail } from '@/app/types/lambda-types'
import { getShipDetailsByTTID } from '@/utils/queries';
import styles from './ShipDetails.module.css'
import Container from '../../globals/container';
import PageSection from '../../globals/page_section';
import { Overview } from '../../../types/lambda-types';
import HorizontalScrollView from '../../globals/carousels/custom_carousel';
import DestinationHightlight from '../destination_highlights/destination_highlight';
import ThingsToDoItem from '../things_to_do/things_to_do_item';

const ShipDetails = ({ blok }: { blok: ShipDetailsStoryblok }) => {
  const [shipDetail, setShipDetail] = useState<ShipDetail | null>(null)

  const fetchShipDetails = async () => {
    try {
      const response = await getShipDetailsByTTID(parseInt(blok.ship_tt_id || '0'));
      setShipDetail(response);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchShipDetails();
  }, [])

  return (
    <div {...storyblokEditable(blok)}>
      <PageSection>
        <Container>
          <h1 className={styles.title}>Overview</h1>
          {shipDetail?.overview.map((overview: Overview) => (
            <div key={overview.id}>
              {overview.description.map((description: string) => (
                <p key={description}>{description}</p>
              ))}
            </div>
          ))}
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <h1 className={styles.title}>Bars and Lounges</h1>
        </Container>
        <HorizontalScrollView className={styles.horizontalScroll}>
          {shipDetail?.barsandlounges.map((overview: Barsandlounge) => (
            <ThingsToDoItem key={overview.name} items={{
              title: overview.name,
              description: overview.description,
              background_image: overview.image,
            }} />
          ))}
        </HorizontalScrollView>
      </PageSection>


      <PageSection>
        <Container>
          <h1 className={styles.title}>Activities</h1>
        </Container>
        <HorizontalScrollView className={styles.horizontalScroll}>
          {shipDetail?.activities.map((overview: Barsandlounge) => (
            <ThingsToDoItem key={overview.name} items={{
              title: overview.name,
              description: overview.description,
              background_image: overview.image,
            }} height='short' />
          ))}
        </HorizontalScrollView>
      </PageSection>
    </div>
  )
}

export default ShipDetails