import React from 'react';
import { storyblokEditable } from "@storyblok/react/rsc";
import type { FeatureStoryblok } from '@/app/types/component-types-sb';

const Feature = ({ blok }: { blok: FeatureStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.name}
    </div>
  )
}

export default Feature;