import styles from './PriceCircle.module.css';

type Props = {
  price: number | string;
}

const PriceCircle = ({ price }: Props) => {

  const getPriceString = (value: string | number): string => {
    const num = Number(value);
    if (!isNaN(num)) {
      return `£${Math.floor(num)}`;
    }
    return `${value}`;
  }

  return (
    <div className={styles.circle}>
      <p className={styles.bookNow}>Book Now</p>
      <div className={styles.priceWrapper}>
        <span className={styles.from}>Fr</span>
        <span className={styles.price}>{getPriceString(price)}</span>
        <span className={styles.pp}>PP*</span>
      </div>
    </div>
  );
}

export default PriceCircle;