import React from 'react';
import styles from './SearchBar.module.css';

type CruiseLine = {
  label: string;
  value: string;
  selected?: boolean;
};

type Ship = {
  label: string;
  value: string;
  group: number;
  selected?: boolean;
};

interface LineShipDropdownProps {
  cruiseLines: CruiseLine[];
  ships: Ship[];
  selectedLine: CruiseLine;
  selectedShip: Ship | null;
  onLineChange: (line: CruiseLine) => void;
  onShipChange: (ship: Ship) => void;
}

const LineShipDropdown: React.FC<LineShipDropdownProps> = ({
  cruiseLines,
  ships,
  selectedLine,
  selectedShip,
  onLineChange,
  onShipChange
}) => {
  const filteredShips = ships.filter(
    ship => selectedLine.value === '' || ship.group.toString() === selectedLine.value
  );

  return (
    <div className={styles.dropdownLinesShips}>
      <div className={styles.dropdownDropdownListOptions}>
        {cruiseLines.map((line) => (
          <button
            key={line.value}
            className={`${styles.listOption} ${selectedLine.value === line.value ? styles.listOptionSelected : ''
              }`}
            onClick={() => onLineChange(line)}
          >
            {line.label}
          </button>
        ))}
      </div>
      <div className={styles.dropdownLinesShipsShips}>
        <div></div>
        <div className={styles.advancedSearchShips}>
          <select
            value={selectedShip?.value || ''}
            onChange={(e) => {
              const ship = ships.find(s => s.value === e.target.value);
              if (ship) onShipChange(ship);
            }}
          >
            <option value="">Any Ship</option>
            {filteredShips.map((ship) => (
              <option key={ship.value} value={ship.value}>
                {ship.label}
              </option>
            ))}
          </select>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default LineShipDropdown;