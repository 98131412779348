import { InspirationalIntroStoryblok } from "@/app/types/component-types-sb"
import styles from "./Intro.module.css"
import PageSection from "../../globals/page_section"
import { storyblokEditable } from "@storyblok/react/rsc"
import Container from "../../globals/container"

const InspirationalIntro = ({ blok }: { blok: InspirationalIntroStoryblok }) => {
    return (
        <div {...storyblokEditable(blok)}>
            <PageSection>
                <Container>
                    <p className={styles.intro}>{blok.text}</p>
                </Container>
            </PageSection>
        </div>
    )
}

export default InspirationalIntro