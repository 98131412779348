/**
 * Sets a cookie with the specified name, value, and optional expiration date.
 *
 * @param name - The name of the cookie to set.
 * @param value - The value to be stored in the cookie.
 * @param options - An optional object containing additional cookie options.
 * @param options.expires - An optional Date object specifying when the cookie should expire.
 * @returns void
 */
export const setCookie = (name: string, value: string, options: { expires?: Date } = {}): void => {
  let cookieString = `${name}=${value}`;
  if (options.expires) {
    cookieString += `; expires=${options.expires.toUTCString()}`;
  }
  cookieString += '; path=/';
  document.cookie = cookieString;
};



/**
 * Retrieves the value of a specific cookie from the document's cookies.
 *
 * @param name - The name of the cookie to retrieve.
 * @returns The value of the specified cookie if found, or null if the cookie doesn't exist.
 */
export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};
