import { Article as ArticleType } from "@/app/types/lambda-types";
import React from "react";
import styles from "./CategoryView.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@awesome.me/kit-d4c82d9167/icons/classic/solid";
import Link from "next/link";

type Props = {
  article: ArticleType;
  className?: string;
};

const Article = ({ article, className }: Props) => {
  return (
    <div className={`${styles.title} ${className}`}>
      <FontAwesomeIcon icon={faBook} />
      <Link
        className={styles.articleTitleLink}
        href={`/knowledge-base/article/${article.uuid}`}
      >
        {article.name}
      </Link>
    </div>
  );
};

export default Article;
