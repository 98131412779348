'use client'
import React, { HTMLAttributes, MouseEventHandler, useRef, useState } from 'react';
import styles from './Carousel.module.css';
import { useDraggable } from "react-use-draggable-scroll";
import clsx from 'clsx';

type Props = {
  children: React.ReactNode
  className?: string
}

const HorizontalScrollView = ({ children, className, ...props }: Props & HTMLAttributes<HTMLDivElement>) => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
  });

  
  const childrenArray = React.Children.toArray(children);

  return (
    <div className={styles.scrollWrapper}>
      <div
        className={clsx(styles["scroll-container"], className)}
        ref={ref}
        {...events}
        {...props}
      >
        {childrenArray.map((item, index) => (
          <div className={styles["scroll-item"]} key={index}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScrollView;