import React from "react";
import Image from '@/app/components/globals/image/';
import { storyblokEditable } from "@storyblok/react";
import type { MediumSliderSlideStoryblok, MediumSliderStoryblok } from "@/app/types/component-types-sb";
import MediumSliderSlide from "./MediumSliderSlide";
import styles from './MediumSlider.module.css';
import Carousel from "../../globals/carousels/carousel";
import { EmblaOptionsType } from 'embla-carousel';

const OPTIONS: EmblaOptionsType = { dragFree: true, loop: false, align: 'center' };

const MediumSlider = ({ blok }: { blok: MediumSliderStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} className="page-section">
      <div className={styles.background}>
        {blok.background ?
          <Image asset={blok.background} /> : null
        }
        <div className="medium-slider-slides">
          <Carousel options={OPTIONS}>
            {
              blok.slides?.map((slide: MediumSliderSlideStoryblok) => (
                <MediumSliderSlide blok={slide} key={slide._uid} />
              ))
            }
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default MediumSlider;