import { KnowledgeBaseArticleViewType } from "@/app/types/lambda-types";
import { getKnowledgeBaseArticleView } from "@/utils/queries";
import { useParams } from "next/navigation";
import styles from "./ArticleView.module.css";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Container from "@/app/components/globals/container";
import PageSection from "@/app/components/globals/page_section";
import Article from "../category_view/Article";
import { Article as ArticleType } from "@/app/types/lambda-types";
import RelatedCategory from "../category_view/RelatedCategory";
import Button from "@/app/components/globals/button";

const ArticleView = () => {
  const params = useParams();
  const [response, setResponse] = useState<KnowledgeBaseArticleViewType>();
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getKnowledgeBaseArticleView(params.uuid as string);
      setResponse(data);
    };
    fetchData();
  }, []);

  const getCombinedResults = () => {
    const articles = response?.articles || [];
    const categories = response?.categories || [];
    const combined = [...articles, ...categories];
    
    return showAll ? combined : combined.slice(0, 5);
  };

  return (
    <PageSection>
      <Container>
        <h1 className={styles.title}>
          {response?.breadcrumbs?.[response?.breadcrumbs?.length - 1]?.name}
        </h1>
        <div className={styles.articleView}>
          <div className={styles.articleContent}>
            <ReactMarkdown>{response?.content ?? ""}</ReactMarkdown>
          </div>
          <div className={styles.relatedArticles}>
            <h3>Related Articles</h3>
            {getCombinedResults().map(item => 
              'children' in item ? (
                <RelatedCategory key={item.uuid} category={item} />
              ) : (
                <Article key={item.uuid} article={item as ArticleType} />
              )
            )}
            {(response?.articles?.length || 0) + (response?.categories?.length || 0) > 5 && (
              <Button 
                onClick={() => setShowAll(!showAll)}
                variant="transparent"
              >
                {showAll ? "Show Less" : "Show More"}
              </Button>
            )}
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default ArticleView;
