import { HolidayExtra } from "@/app/types/lambda-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHotel } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";
import { faPlane } from "@awesome.me/kit-d4c82d9167/icons/classic/regular";

import styles from './StarBuy.module.css';

const Included = ({ holidayExtra, mergeHotels }: { holidayExtra: HolidayExtra, mergeHotels?: boolean }) => {

  const getIcon = (extra: HolidayExtra) => {
    if (extra.type === 'hotel')
      return <FontAwesomeIcon icon={faHotel} />;
    else if (extra.type === 'flight')
      return <FontAwesomeIcon icon={faPlane} />;
    return null;
  }

  const getTitle = (extra: HolidayExtra) => {
    if (extra.type === 'hotel') {
      if (mergeHotels)
        return `${extra.category === 'precruise' ? 'Pre' : 'Post'}-Cruise Hotel`;
      else
        return `${extra.nights}-night ${extra.category === 'precruise' ? 'Pre' : 'Post'}-Cruise Hotel`;
    } else if (extra.type === 'flight')
      return `Flights`;
    return null;
  }

  const detail = {
    icon: getIcon(holidayExtra),
    title: getTitle(holidayExtra)
  }

  return (
    <div className={styles.include}>
      {detail.icon}
      {detail.title}
    </div>
  );
}

export default Included;